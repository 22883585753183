
import Vue from 'vue'
import { passwordAllowedSymbolsRegExp, passwordSpecialCharacters } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'PasswordRepairSetup',
  data: () => ({
    form: {
      valid: false,
      fields: {
        new_password1: {
          value: '',
          type: 'password',
          errorMessages: [],
          rules: [
            (v: string) => Boolean(v.length) || 'newPassword.errors.required',
            (v: string) => v.length >= 8 || 'newPassword.errors.fieldIsShort',
            (v: string) => passwordAllowedSymbolsRegExp.test(v) || 'newPassword.errors.onlyLatin',
            (v: string) => v.search(/[a-z]/g) >= 0 || 'newPassword.errors.lowerCharsRequired',
            (v: string) => v.search(/[A-Z]/g) >= 0 || 'newPassword.errors.upperCharsRequired',
            (v: string) => v.search(/[0-9]/g) >= 0 || 'newPassword.errors.numbersRequired',
            (v: string) => passwordSpecialCharacters.test(v) || 'newPassword.errors.specialSymbol'
          ]
        },
        new_password2: {
          value: '',
          type: 'password',
          errorMessages: [],
          rules: [
            (v: string) => Boolean(v.length) || 'newPassword.errors.confirmRequired',
            (v: string) => v.length >= 8 || 'newPassword.errors.fieldIsShort',
            (v: string) => passwordAllowedSymbolsRegExp.test(v) || 'newPassword.errors.onlyLatin',
            (v: string) => v.search(/[a-z]/g) >= 0 || 'newPassword.errors.lowerCharsRequired',
            (v: string) => v.search(/[A-Z]/g) >= 0 || 'newPassword.errors.upperCharsRequired',
            (v: string) => v.search(/[0-9]/g) >= 0 || 'newPassword.errors.numbersRequired',
            (v: string) => passwordSpecialCharacters.test(v) || 'newPassword.errors.specialSymbol'
          ]
        }
      },
      responseErrors: [],
      isLoading: false,
      passwordPattern: passwordAllowedSymbolsRegExp.source
    }
  }),
  watch: {
    'form.fields.new_password2.value' (v: string) {
      const vm = this as any
      const item: any = vm.form.fields.new_password1
      const item2: any = vm.form.fields.new_password2
      const index = item.errorMessages.findIndex((item: any) => item === 'newPassword.errors.notEqual')
      if (v !== item.value) {
        if (index < 0) {
          item.errorMessages.push('newPassword.errors.notEqual')
          item2.errorMessages.push('newPassword.errors.notEqual')
        }
      } else {
        item.errorMessages.splice(index, 1)
        item2.errorMessages.splice(index, 1)
      }
    }
  },
  methods: {
    changePasswordInputType (item: string): void {
      const vm = this as any
      vm.form.fields[item].type = vm.form.fields[item].type === 'password' ? 'text' : 'password'
    },
    checkPasswordsEqual (): void {
      const vm = this as any
      const p1 = vm.form.fields.new_password1.value
      const p2 = vm.form.fields.new_password2.value
      if (p1.length && p2.length) {
        if (p1 !== p2) {
          if (!vm.form.fields.new_password2.errorMessages.length) {
            vm.form.fields.new_password2.errorMessages.push('This value should be equal value of field above')
          }
        } else {
          vm.form.fields.new_password2.errorMessages.shift()
        }
      }
    },
    async validate () {
      const vm = this as any

      const payload = {
        password1: this.form.fields.new_password1.value,
        password2: this.form.fields.new_password2.value
      }
      const sessionId = this.$route.query.an3K8QkkJb3Mzz
      vm.form.isLoading = true
      const response: any = await vm.$services.authorization.repair.doneRepair({ sessionId, payload })
      vm.form.isLoading = false

      if (response.success) this.$router.replace({ name: 'SignIn' })

      else {
        vm.form.responseErrors = []
        for (const [key, value] of Object.entries(response.data) as any) {
          for (const err of value) {
            vm.form.responseErrors.push(err)
          }
        }
      }
    }
  }
})
